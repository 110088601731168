body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.cursor-gradient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.blinking-cursor {
  display: inline-block;
  width: 4px; /* Thickness of the cursor */
  height: 1em; /* Matches the height of the text */
  background-color: white; /* Cursor color */
  margin-left: 2px; /* Space between text and cursor */
  animation: blink 0.7s step-end infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
